import { GraphQLClient } from "graphql-request"

export const buildGraphqlClient = Authorization => {
  const client = new GraphQLClient(
    `${process.env.GATSBY_API_GRAPHQL}/omdc/v1/graphql`,
    {
      headers: {
        "x-api-key": process.env.GATSBY_API_GRAPHQL_APIKEY,
        Authorization,
      },
    }
  )

  return (query, variables) => client.request(query, variables)
}
