import { gql } from "@apollo/client"
import { USER_FRAGMENT } from "./fragments"

export const UPDATE_USER = gql`
  ${USER_FRAGMENT}
  mutation updateUser($user: UserProfile!) {
    updateUser(user: $user) {
      ...userFragment
    }
  }
`

export const CREATE_USER = gql`
  ${USER_FRAGMENT}
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      ...userFragment
    }
  }
`

export const SUBSCRIBE_NEWSLETTER = gql`
  mutation subscribeNewsletter($subscribeInput: SubscribeInput!) {
    subscribeNewsletter(subscribeInput: $subscribeInput)
  }
`

export const GET_VIEWER_VIEW_URL = gql`
  query getViewerVideoURl($key: String!) {
    getViewerVideoURl(key: $key)
  }
`

export const GET_UPLOAD_URL = gql`
  mutation getUploadSignedUlr(
    $folder: String!
    $key: String!
    $mymeType: MYME_TYPE!
  ) {
    getUploadSignedUlr(folder: $folder, key: $key, mymeType: $mymeType) {
      uploadUlr
      publicUrl
    }
  }
`
