import { gql } from "@apollo/client"

export const MATCH_FRAGMENT = gql`
  fragment match on Match {
    id
    category
    rounds
    numberOfFields
    team {
      id
      title
      status
      captain
    }
    game {
      id
      name
      uid
      players
      requiresMusic
    }
    players {
      id
      tournamentMembershipId
      user {
        id
        name
        nameCapilize
        email
        nickname
        picture
        uid
      }
      dog {
        id
        name
        nameCapilize
        race
        profile
        birthday
      }
      roller
      fivePoints
      order
      party
      music
      field
    }
  }
`

export const MATCH_NO_PLAYERS_FRAGMENT = gql`
  fragment matchNoPlayers on Match {
    id
    category
    rounds
    numberOfFields
    team {
      id
      title
      status
      captain
      logoSrc
    }
    game {
      id
      name
      uid
      players
      requiresMusic
    }
  }
`

export const TOURNAMENT_ENROLLMENT = gql`
  fragment tournamentEnrollment on Tournament {
    id
    league {
      name
      description
      startsAt
      endsAt
      logoSrc
    }
    match_type
    requireParking
    events {
      id
      title
      subTitle
    }
    teams {
      id
      title
      status
      captain
    }
    name
    startsAt
    endsAt
    inscription {
      startsAt
      endsAt
    }
    logoSrc
    matches {
      ...matchNoPlayers
    }
  }
`

export const USER_FRAGMENT = gql`
  fragment userFragment on User {
    id
    email
    email_verified
    name
    nickname
    picture
    uid
    completedProfile
    memberships {
      type
      isExpired
      subscriptions {
        startAt
        expiresAt
      }
    }
    scopes
    dogs {
      id
      name
      race
      profile
      birthday
    }
  }
`
