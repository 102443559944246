import React from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { ApolloContext } from "./ApolloContext"
import { AuthContextProvider } from "./AuthProvider"

export const wrapRootElement = ({ element }) => {
  return (
    <AuthContextProvider>
      <ApolloContext>
        <ToastContainer autoClose={3500} />
        {element}
      </ApolloContext>
    </AuthContextProvider>
  )
}
