exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-live-managment-js": () => import("./../../../src/pages/live-managment.js" /* webpackChunkName: "component---src-pages-live-managment-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-player-js": () => import("./../../../src/pages/player.js" /* webpackChunkName: "component---src-pages-player-js" */),
  "component---src-pages-profile-admin-tournaments-enrollments-index-js": () => import("./../../../src/pages/profile/admin/tournaments/enrollments/index.js" /* webpackChunkName: "component---src-pages-profile-admin-tournaments-enrollments-index-js" */),
  "component---src-pages-recovery-password-js": () => import("./../../../src/pages/recovery-password.js" /* webpackChunkName: "component---src-pages-recovery-password-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-omdc-page-js": () => import("./../../../src/templates/omdcPage.js" /* webpackChunkName: "component---src-templates-omdc-page-js" */),
  "component---src-templates-omdc-protected-page-js": () => import("./../../../src/templates/omdcProtectedPage.js" /* webpackChunkName: "component---src-templates-omdc-protected-page-js" */)
}

