// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDh0xYzDY-CHoXS06gHA2KLaIT_b7hz7r4",
  authDomain: "omdc-app.firebaseapp.com",
  databaseURL: "https://omdc-app-default-rtdb.firebaseio.com",
  projectId: "omdc-app",
  storageBucket: "omdc-app.appspot.com",
  messagingSenderId: "878780205420",
  appId: "1:878780205420:web:251552bae15af3cd290b49",
  measurementId: "G-1JZX9ZHN9K",
}

// Initialize Firebase
const app = typeof window !== "undefined" && initializeApp(firebaseConfig)
export const auth = typeof window !== "undefined" && getAuth(app)
export const database = typeof window !== "undefined" && getDatabase(app)
